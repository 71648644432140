import { Button, Col, Dropdown, Layout, Menu, Row, Space } from "antd";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import Home from "./Home";
import LogoMaply from "./assets/LogoMaply_BB.svg";
import app from "./base";

const { Header } = Layout;

const ProfileDropDown = ({ loggedUser }) => {
  const [userEmail, setuserEmail] = useState("");
  const menu = (
    <Menu>
      <Menu.Item key="logOut" danger onClick={() => app.auth().signOut()}>
        Encerrar Sessão
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    try {
      var email = loggedUser.email;
    } catch (e) {
      var email = "";
    }
    setuserEmail(email);
  }, [loggedUser]);
  return (
    <div>
      <Space>
        <Dropdown overlay={menu}>
          <Button>
            {userEmail}
            <DownOutlined></DownOutlined>
          </Button>
        </Dropdown>
      </Space>
    </div>
  );
};

const MainPage = ({ loggedUser }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [pathName, setPathname] = useState("");
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    setPathname(location.pathname);
  }, [location, collapsed]);

  return (
    <div>
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <Header
            className="site-layout-background"
            style={{ padding: 0, backgroundColor: "white" }}
          >
            <Row justify="space-between" align="middle">
              <Col span={4}>
                <img
                  src={LogoMaply}
                  alt="React Logo"
                  style={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right",
                    marginLeft: "25px",
                  }}
                />
              </Col>
              <Col span={4}></Col>
              <Col span={4}></Col>
              <Col span={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right",
                    marginRight: "25px",
                  }}
                >
                  <ProfileDropDown loggedUser={loggedUser} />
                </div>
              </Col>
            </Row>
          </Header>
          <Home />
        </Layout>
      </Router>
    </div>
  );
};

export default MainPage;
