import { useMutation } from "@apollo/client";
import { Button, Col, Drawer, Row, Slider } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { UPDATE_VECTORS } from "./GraphQL-Client/Queries";

export const InfoSideBar = ({ visible, setVisible, selectedFeatures, deviceId, onSelectedFeaturesChanged }) => {
  const [isloading, setIsLoading] = useState(false);
  const [updateFeatures,] = useMutation(UPDATE_VECTORS);
  function formatter(value) {
    return `${(value * 100).toFixed(0)}%`;
  }

  const [progress, setProgress] = useState(0);

  const featureIds = useMemo(() => selectedFeatures.map((feat) => feat.id), [selectedFeatures]);

  const pluralOrSingularText = (length) => (length > 1) ? 'vetores selecionados' : 'vetor selecionado';

  useEffect(() => {
    const medianProgress = (selectedFeatures.reduce((total, item) => total + item.properties.progress, 0) / selectedFeatures.length) || 0;
    setProgress(medianProgress);
  }, [selectedFeatures]);

  const isDirty = useMemo(() => {
    const medianProgress = (selectedFeatures.reduce((total, item) => total + item.properties.progress, 0) / selectedFeatures.length) || 0;
    return medianProgress !== progress
  }, [selectedFeatures, progress]);

  const changeProgress = (progress1) => {
    if (featureIds.length > 0) {
      setIsLoading(true);
      updateFeatures({ variables: { ids: featureIds, progress: progress, deviceId: deviceId, timestamp: "now()" } }).catch((error) => {
        console.log(error)
      }).then(() => {
        setIsLoading(false);
      })
      const fs = selectedFeatures.map((feature) => {
        feature.properties.progress = progress;
        return feature;
      });
      return onSelectedFeaturesChanged(fs);
    }
    return false;
  }

  return (
    <Drawer
      title={`${selectedFeatures.length} ${pluralOrSingularText(selectedFeatures.length)}`}
      placement="right"
      closable={false}
      visible={visible}
      mask={false}
    >
      <Row>
        <Col span={20}>
          <Slider
            tipFormatter={formatter}
            onChange={(e) => setProgress(e)}
            value={progress}
            step={0.25}
            min={0}
            max={1}
          />
        </Col>
        <Col span={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px"
            }}
          >
            {formatter(progress)}
          </div>
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px"
        }}
      >
        <Button onClick={() => changeProgress(progress)} disabled={!isDirty} loading={isloading}>
          Atualizar progresso
        </Button>
      </div>
    </Drawer>
  );
};
