import { Button, Card, Col, Divider, Form, Input, Row } from "antd";
import React, { useCallback, useState } from "react";

import MaplyLogo from "./assets/LogoMaply_BB.svg";
import app from "./base";

const Login = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const onFinish = useCallback(
    async (data) => {
      console.log(data);
      setLoading(true);
      try {
        await app.auth().signInWithEmailAndPassword(data.email, data.password);
        history.push("/dashboard");
        setLoading(false);
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ width: "100%", backgroundColor: "black", height: "100vh" }}>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12, offset: 6 }}
          md={{ span: 10, offset: 7 }}
          lg={{ span: 8, offset: 8 }}
        >
          <Card
            title={
              <img
                src={MaplyLogo}
                alt="React Logo"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  width: "120px",
                  padding: "10px",
                }}
              />
            }
            style={{ marginTop: "50%" }}
          >
            <Form
              layout="vertical"
              name="login"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Divider />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{ width: "100%" }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
