export const treeData = [
  {
    title: "TERRAPLENAGEM",
    key: "2-01-08-01",
    code: "2.01.08.01",
    expandAction: "doubleClick",
    children: [
      {
        title: "SUPRESSÃO VEGETAL E DESTOCAMENTO",
        key: "2-01-08-01-01",
        code: "2.01.08.01.01",
        expandAction: "doubleClick",
        children: [
          {
            title:
              "Desmatamento e destocamento de areas com arv. de diam ate 0,15m",
            key: "2-01-08-01-01-01",
            code: "2.01.08.01.01.01"
          }
        ]
      },
      {
        title: "LIMPEZA DE CAMADA SUPERFICIAL E ESPALHAMENTO DE CAMADA VEGETAL",
        key: "2-01-08-01-02",
        code: "2.01.08.01.02",

        expandAction: "doubleClick",
        children: [
          {
            title: "Raspagem Camada Vegetal (H=20CM)",
            key: "2-01-08-01-02-01",
            code: "2.01.08.01.02.01"
          },
          {
            title:
              "Carga,transporte e descarga de mat vegetal em bota espera interno e carga, transporte e espalhamento com patrol ou pá carregadeira",
            key: "2-01-08-01-02-02",
            code: "2.01.08.01.02.02"
          }
        ]
      },
      {
        title: "ESCAV CARGA E TRANSPORTE MAT 1A CAT",
        key: "2-01-08-01-03",
        code: "2.01.08.01.03",

        expandAction: "doubleClick",
        children: [
          {
            title:
              "Esc. carga transp. mat 1ª cat DMT 200 a 400m c/e (medido no corte)",
            key: "2-01-08-01-03-01",
            code: "2.01.08.01.03.01"
          }
        ]
      },
      {
        title: "COMPACTACAO DE ATERROS",
        key: "2-01-08-01-04",
        code: "2.01.08.01.04",

        expandAction: "doubleClick",
        children: [
          {
            title: "Compactação de aterros a 100% proctor normal",
            key: "2-01-08-01-04-01",
            code: "2.01.08.01.04.01"
          }
        ]
      }
    ]
  },
  {
    title: "OBRAS DE CONTENÇÃO",
    key: "2-01-08-02",
    code: "2.01.08.02",

    expandAction: "doubleClick",
    children: [
      {
        title: 'MURO DE ARRIMO EM "LOCK & LOAD"',
        key: "2-01-08-02-01",
        code: "2.01.08.02.01",

        expandAction: "doubleClick",
        children: [
          {
            title: "Execução de muro de arrimo em lock and load",
            key: "2-01-08-02-01-01",
            code: "2.01.08.02.01.01"
          }
        ]
      }
    ]
  },
  {
    title: "DRENAGEM DE AGUAS PLUVIAIS",
    key: "2-01-08-03",
    code: "2.01.08.03",

    children: [
      {
        title: "EXECUCAO DE TUBULACÕES DE CONCRETO",
        key: "2-01-08-03-01",
        code: "2.01.08.03.01",

        children: [
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 300mm, Classe PA-1, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-03-01-01",
            code: "2.01.08.03.01.01"
          },
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 400mm Classe PA-1, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-03-01-02",
            code: "2.01.08.03.01.02"
          },
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 500mm, Classe PA-2, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-03-01-03",
            code: "2.01.08.03.01.03"
          },
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 600mm, Classe PA-2, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-03-01-04",
            code: "2.01.08.03.01.04"
          },
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 800mm, Classe PA-2, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-03-01-05",
            code: "2.01.08.03.01.05"
          },
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 1000mm Classe PA-2, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-03-01-06",
            code: "2.01.08.03.01.06"
          }
        ]
      },
      {
        title: "POCO DE VISITA / CAIXAS DE LIGAÇÃO",
        key: "2-01-08-03-02",
        code: "2.01.08.03.02",

        children: [
          {
            title:
              "Execucao de Poco de Visita em alvenaria estrutural  - D tub de 0,50 até 0,80m - H= 1,00m, incluindo reaterro em camadas sucessivas (20cm) a 100%PN",
            key: "2-01-08-03-02-01",
            code: "2.01.08.03.02.01"
          },
          {
            title:
              "Execucao de Poco de Visita em alvenaria estrutural- D tub de 1,00 até 1,50m - H= 1,30m, incluindo reaterro em camadas sucessivas (20cm) a 100%PN",
            key: "2-01-08-03-02-02",
            code: "2.01.08.03.02.02"
          }
        ]
      },
      {
        title: "CAIXA DE PASSAGEM",
        key: "2-01-08-03-03",
        code: "2.01.08.03.03",

        children: [
          {
            title:
              "Execucao de Caixa de Ligacao - D tub= 0,80m - H= 1,00m (sem dispositivo de queda), incluindo reaterro em camadas sucessivas (20cm) a 100%PN",
            key: "2-01-08-03-03-01",
            code: "2.01.08.03.03.01"
          }
        ]
      },
      {
        title: "BOCA DE LEAO",
        key: "2-01-08-03-04",
        code: "2.01.08.03.04",

        children: [
          {
            title:
              "Execução de Boca de Leão dupla em alvenaria ou concreto armado contra-barranco com fornecimento de grelha em aço, resistente à trafego pesado",
            key: "2-01-08-03-04-01",
            code: "2.01.08.03.04.01"
          }
        ]
      },
      {
        title: "CANALETAS DE DRENAGEM",
        key: "2-01-08-03-05",
        code: "2.01.08.03.05",

        children: [
          {
            title:
              "Canaleta (meia cana) de concreto -  D= 0,40 m (Canaleta em base de muro)",
            key: "2-01-08-03-05-01",
            code: "2.01.08.03.05.01"
          }
        ]
      },
      {
        title: "DESCIDAS D AGUA",
        key: "2-01-08-03-06",
        code: "2.01.08.03.06",

        children: [
          {
            title: "Descidas d'água tipo escada conforme projeto",
            key: "2-01-08-03-06-01",
            code: "2.01.08.03.06.01"
          }
        ]
      },
      {
        title: "DISSIPADORES E MUROS DE ALAS DE SAIDA DE BUEIROS",
        key: "2-01-08-03-07",
        code: "2.01.08.03.07",

        children: [
          {
            title: "Dissipador de energia - 2,40 X 2,42m conforme projeto",
            key: "2-01-08-03-07-01",
            code: "2.01.08.03.07.01"
          },
          {
            title: "Dissipador de energia 4,00 X 3,45m conforme projeto",
            key: "2-01-08-03-07-02",
            code: "2.01.08.03.07.02"
          },
          {
            title: "Boca de bueiro simples tubular BST 30CM conforme projeto",
            key: "2-01-08-03-07-03",
            code: "2.01.08.03.07.03"
          },
          {
            title: "Boca de bueiro simples tubular BST 50CM  conforme projeto",
            key: "2-01-08-03-07-04",
            code: "2.01.08.03.07.04"
          },
          {
            title: "Boca de bueiro simples tubular BST 100CM  conforme projeto",
            key: "2-01-08-03-07-05",
            code: "2.01.08.03.07.05"
          }
        ]
      },
      {
        title: "OBRAS DE ARTES ESPECIAIS",
        key: "2-01-08-03-08",
        code: "2.01.08.03.08",

        children: [
          {
            title:
              "Corpo BSCC - seção 1,5 x 1,5 m fechada - pré-moldado  - incluindo estabilização do subleiro e fundação e reaterro  (apresentar projeto estrutural com carga compativel com aterro e trafego no local)",
            key: "2-01-08-03-08-01",
            code: "2.01.08.03.08.01"
          },
          {
            title:
              "Boca de bueiro simples celular de concreto BSCC 1,50 x 1,50m (padrao DNIT)",
            key: "2-01-08-03-08-02",
            code: "2.01.08.03.08.02"
          },
          {
            title:
              "Caixa de transição - 1,80 x 2,00m - H=6,15m em concreto moldado in-loco",
            key: "2-01-08-03-08-03",
            code: "2.01.08.03.08.03"
          },
          {
            title:
              "Travessia de fauna subterrânea (1,50x2,00m) conforme projeto  - pré-moldado  - incluindo estabilização do subleito/fundação e reaterro  (apresentar projeto estrutural com carga compativel com aterro e trafego no local)",
            key: "2-01-08-03-08-04",
            code: "2.01.08.03.08.04"
          }
        ]
      }
    ]
  },
  {
    title: "REDE DE AGUA",
    key: "2-01-08-04",
    code: "2.01.08.04",

    children: [
      {
        title: "EXECUCAO DE TUBULACÕES EM PVC",
        key: "2-01-08-04-01",
        code: "2.01.08.04.01",

        children: [
          {
            title:
              "Forn e assent de tubo de PVC-PBA JEI CL20 - D= 50mm,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-04-01-01",
            code: "2.01.08.04.01.01"
          },
          {
            title:
              "Forn e assent de tubo de PVC-PBA JEI  CL20 - D= 75mm, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-04-01-02",
            code: "2.01.08.04.01.02"
          },
          {
            title:
              "Forn e assent de tubo de PVC-PBA JEI  CL20 - D=100mm,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-04-01-03",
            code: "2.01.08.04.01.03"
          },
          {
            title:
              "Forn e assent de tubo de PVC DEFoFo JEI - D=150mm,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-04-01-04",
            code: "2.01.08.04.01.04"
          },
          {
            title:
              'Ramal domiciliar em pead de 1/2" com 3m de comprimento, incluindo colar de tomada p/ligação na rede',
            key: "2-01-08-04-01-05",
            code: "2.01.08.04.01.05"
          }
        ]
      }
    ]
  },
  {
    title: "REDE DE ESGOTO",
    key: "2-01-08-05",
    code: "2.01.08.05",

    children: [
      {
        title: "EXECUCAO DE TUBULACÕES EM PVC",
        key: "2-01-08-05-01",
        code: "2.01.08.05.01",

        children: [
          {
            title:
              "TUBO PVC OCRE PTA/BLS JEI DN150MM,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-05-01-01",
            code: "2.01.08.05.01.01"
          },
          {
            title:
              "TUBO PVC OCRE PTA/BLS JEI DN200MM,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-05-01-02",
            code: "2.01.08.05.01.02"
          },
          {
            title:
              "TUBO DE RECALQUE EM PVC-O DN100MM,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-05-01-03",
            code: "2.01.08.05.01.03"
          },
          {
            title:
              "RES EXEC RAMAL LIG DOMICILIAR P/ LOTES DE SERVIDÃO TUBO PVC OCRE PTA/BLS JEI DN100MM (70 UN), com selim de pvc com travas, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-08-05-01-04",
            code: "2.01.08.05.01.04"
          }
        ]
      },
      {
        title: "POCO DE VISITA",
        key: "2-01-08-05-02",
        code: "2.01.08.05.02",

        children: [
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,0 M, H até 1,50 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-08-05-02-01",
            code: "2.01.08.05.02.01"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,0 M, H de 1,50 A 2,00 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-08-05-02-02",
            code: "2.01.08.05.02.02"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,0 M, H de 2,00 A 2,50 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-08-05-02-03",
            code: "2.01.08.05.02.03"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,0 M, H de 2,50 A 3,00 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-08-05-02-04",
            code: "2.01.08.05.02.04"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,0 M, H de 3,00 A 3,50 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-08-05-02-05",
            code: "2.01.08.05.02.05"
          }
        ]
      }
    ]
  },
  {
    title: "FECHAMENTO DO TERRENO",
    key: "2-01-08-06",
    code: "2.01.08.06",

    children: [
      {
        title: "MURO DE FECHAMENTO",
        key: "2-01-08-06-01",
        code: "2.01.08.06.01",

        children: [
          {
            title:
              "FT MURO ASSENT PILARES E PLACAS PREMOLDADOS DE CONCRETO (H=3,00M)COM IMPERMEABILIZAÇÃO NA BASE E REVESTIMENTO MINERAL NAS DUAS FACES",
            key: "2-01-08-06-01-01",
            code: "2.01.08.06.01.01"
          }
        ]
      },
      {
        title: "GRADIL DE FECHAMENTO",
        key: "2-01-08-06-02",
        code: "2.01.08.06.02",

        children: [
          {
            title:
              "FT GRADIL ASSENT PILARES E PLACAS PREMOLDADOS, MONTANTE METÁLICO E TELA SIGRADI MIX (H=2,43M) DE CONCRETO COM IMPERMEABILIZAÇÃO NA BASE E REVESTIMENTO MINERAL NAS DUAS FACES",
            key: "2-01-08-06-02-01",
            code: "2.01.08.06.02.01"
          },
          {
            title:
              "FT GRADIL BASE EM BLOCO DE CONCRETO IMPERMEABILIZADA COM MONTANTES METALICOS E TELA SIGRADI  DEFENCE (H=3,00M)",
            key: "2-01-08-06-02-02",
            code: "2.01.08.06.02.02"
          },
          {
            title:
              "FT GRADIL BASE EM BLOCO DE CONCRETO IMPERMEABILIZADA COM MONTANTES METALICOS E TELA SIGRADI  DEFENCE (H=3,00M)",
            key: "2-01-08-06-02-03",
            code: "2.01.08.06.02.03"
          },
          {
            title:
              "FT GRADIL BASE EM BLOCO DE CONCRETO IMPERMEABILIZADA COM MONTANTES METALICOS E TELA SIGRADI  MIX (H=1,10M)",
            key: "2-01-08-06-02-04",
            code: "2.01.08.06.02.04"
          }
        ]
      },
      {
        title: "CERCAS E ALAMBRADOS",
        key: "2-01-08-06-03",
        code: "2.01.08.06.03",

        children: [
          {
            title: "CERCA DE ARAME (5 FIOS) C/ MOURAO DE EUCALIPTO",
            key: "2-01-08-06-03-01",
            code: "2.01.08.06.03.01"
          },
          {
            title: "ALAMBRADO ESTICADORES METÁLICOS E H=2,00M",
            key: "2-01-08-06-03-02",
            code: "2.01.08.06.03.02"
          }
        ]
      }
    ]
  },
  {
    title: "PAVIMENTACAO",
    key: "2-01-08-07",
    code: "2.01.08.07",

    children: [
      {
        title: "PAVIMENTOS, GUIAS, SARJETAS E VIGAS DE TRANSIÇÃO",
        key: "2-01-08-07-07",
        code: "2.01.08.07.07",

        children: [
          {
            title: "RUAS PAVIMENTADAS (SUBLEITO, SUBBASE, BASE, CAPA)",
            key: "2-01-08-07-07-01",
            code: "2.01.08.07.07.01"
          },
          {
            title:
              "GUIAS E SARJETAS EXTRUSADAS C/ 60CM LARGURA  DE BASE (GUIA BAIXA H=7,5CM) FCK 21MPa, CONSUMO DE CIMENTO >350kG/m³ de concreto",
            key: "2-01-08-07-07-02",
            code: "2.01.08.07.07.02"
          },
          {
            title:
              "GUIAS E SARJETAS EXTRUSADAS C/ 60CM LARGURA  DE BASE ((GUIA ALTA H=15CM) FCK 21MPa, CONSUMO DE CIMENTO >350kG/m³ de concreto",
            key: "2-01-08-07-07-03",
            code: "2.01.08.07.07.03"
          },
          {
            title:
              "Execução de VIGAS DE TRANSIÇÃO entre pavimentos asfálticos e intertravados, concreto armado fck>30MPa",
            key: "2-01-08-07-07-04",
            code: "2.01.08.07.07.04"
          }
        ]
      }
    ]
  },
  {
    title: "REDE ELETRICA E ILUMINAÇÃO PÚBLICA",
    key: "2-01-08-08",
    code: "2.01.08.08",

    children: [
      {
        title: "REDE ELETRICA SUBTERRANEA",
        key: "2-01-08-08-01",
        code: "2.01.08.08.01",

        children: [
          {
            title: "Execução de infraestrutura de tubulações - rede primária",
            key: "2-01-08-08-01-01",
            code: "2.01.08.08.01.01"
          },
          {
            title: "Execução de infraestrutura de tubulações - rede secundária",
            key: "2-01-08-08-01-02",
            code: "2.01.08.08.01.02"
          },
          {
            title: "Travessias sob pavimento",
            key: "2-01-08-08-01-03",
            code: "2.01.08.08.01.03"
          },
          {
            title: "Caixas de inspeção CI",
            key: "2-01-08-08-01-04",
            code: "2.01.08.08.01.04"
          },
          {
            title:
              "Caixas de passagem CS2 (GED 16701) e Caixa de concreto 200 X 200 X 250 MM C/ TAMPA (GED 16706)",
            key: "2-01-08-08-01-05",
            code: "2.01.08.08.01.05"
          },
          {
            title: "Transformadores / QUADRO QDP",
            key: "2-01-08-08-01-06",
            code: "2.01.08.08.01.06"
          }
        ]
      },
      {
        title: "REDE DE ILUMINACAO PUBLICA PARA REDE SUBTERRANEA",
        key: "2-01-08-08-02",
        code: "2.01.08.08.02",

        children: [
          {
            title: "Postes de Iluminação",
            key: "2-01-08-08-02-01",
            code: "2.01.08.08.02.01"
          }
        ]
      }
    ]
  },
  {
    title: "REDE DE TELEFONIA E MULTISERVIÇOS",
    key: "2-01-08-09",
    code: "2.01.08.09",

    children: [
      {
        title: "INFRAESTRUTURA DE  TUBULAÇÕES, CAIXAS DE INSPEÇÃO E PASSAGEM",
        key: "2-01-08-09-01",
        code: "2.01.08.09.01",

        children: [
          {
            title: "Execução de infraestrutura de tubulações",
            key: "2-01-08-09-01-01",
            code: "2.01.08.09.01.01"
          },
          {
            title: "Travessias sob pavimento",
            key: "2-01-08-09-01-02",
            code: "2.01.08.09.01.02"
          },
          {
            title: "Caixas de concreto R2",
            key: "2-01-08-09-01-03",
            code: "2.01.08.09.01.03"
          }
        ]
      }
    ]
  },
  {
    title: "OBRAS CIVIS - INTERNAS EMPREENDIMENTO",
    key: "2-01-08-13",
    code: "2.01.08.13",

    children: [
      {
        title: "OBRAS CIVIS - SISTEMA VIÁRIO E ACESSIBILIDADE",
        key: "2-01-08-13-01",
        code: "2.01.08.13.01",

        children: [
          {
            title:
              "Passerio em concreto armado (tela nervura Q138), com espessura de 5,0cm, fck= 30MPa c/ fibra, de cor natural e acabamento de superfície vassourado, com leito em lona plástica e lastro de brita e juntas de dilatação a cada 2 m",
            key: "2-01-08-13-01-01",
            code: "2.01.08.13.01.01"
          },
          {
            title:
              "Rampas de acessibilidade em concreto armado (tela nervurada  Q138), moldada in loco, incluindo piso podotátil, com dimensões conforme projeto",
            key: "2-01-08-13-01-02",
            code: "2.01.08.13.01.02"
          }
        ]
      }
    ]
  },
  {
    title: "EQUIPAMENTOS PÚBLICOS DE ÁGUA",
    key: "2-01-08-15",
    code: "2.01.08.15",

    children: [
      {
        title: "ESTAÇÃO ELEVATÓRIA DE ÁGUA TRATADA",
        key: "2-01-08-15-01",
        code: "2.01.08.15.01",

        children: [
          {
            title:
              "Forn e assent de tubo de PVC-PBA JEI  CL20 - D= 75mm, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN - TUBULAÇÃO DE RECALQUE",
            key: "2-01-08-15-01-01",
            code: "2.01.08.15.01.01"
          },
          {
            title:
              "Execução de EEAT (fundação, paredes, pinturas e portas, laje superior, etc ) e detalhes - CONFORME PROJETO E LISTA DE MATERIAIS",
            key: "2-01-08-15-01-02",
            code: "2.01.08.15.01.02"
          },
          {
            title:
              "Execução de VCN em caixa de concreto ou alvenaria e detalhes - VERIFICAR PROJETO E LISTA DE MATERIAIS",
            key: "2-01-08-15-01-03",
            code: "2.01.08.15.01.03"
          }
        ]
      },
      {
        title: "RESERVATÓRIO APOIADO - V=31,70 m³",
        key: "2-01-08-15-02",
        code: "2.01.08.15.02",

        children: [
          {
            title: "Reservatorio Apoiado  Metálico",
            key: "2-01-08-15-02-01",
            code: "2.01.08.15.02.01"
          },
          {
            title:
              "Base em concreto armado circular incluindo fundação com 12 estacas",
            key: "2-01-08-15-02-02",
            code: "2.01.08.15.02.02"
          }
        ]
      },
      {
        title: "RESERVATÓRIO ELEVADO - V=23,80 m³",
        key: "2-01-08-15-03",
        code: "2.01.08.15.03",

        children: [
          {
            title: "Reservatorio Elevado  Metalico",
            key: "2-01-08-15-03-01",
            code: "2.01.08.15.03.01"
          },
          {
            title:
              "Base em concreto armado circular incluindo fundação com 12 estacas",
            key: "2-01-08-15-03-02",
            code: "2.01.08.15.03.02"
          }
        ]
      }
    ]
  },
  {
    title: "TERRAPLENAGEM",
    key: "2-01-10-01",
    code: "2.01.10.01",

    children: [
      {
        title: "SUPRESSÃO VEGETAL/DESTOCAMENTO",
        key: "2-01-10-01-01",
        code: "2.01.10.01.01",

        children: [
          {
            title:
              "Desmatamento e Destocamento de areas com arv. de Diam > 30 e < 60cm",
            key: "2-01-10-01-01-01",
            code: "2.01.10.01.01.01"
          }
        ]
      },
      {
        title: "LIMPEZA DE CAMADA SUPERFICIAL",
        key: "2-01-10-01-02",
        code: "2.01.10.01.02",

        children: [
          {
            title: "Raspagem Camada Vegetal (H=20CM)",
            key: "2-01-10-01-02-01",
            code: "2.01.10.01.02.01"
          },
          {
            title:
              "Carga, transporte e descarga de mat vegetal em bota espera interno e carga, transporte e espalhamento com patrol ou pá carregadeira",
            key: "2-01-10-01-02-02",
            code: "2.01.10.01.02.02"
          }
        ]
      },
      {
        title: "ESCAV CARGA E TRANSPORTE MAT 1A CAT",
        key: "2-01-10-01-03",
        code: "2.01.10.01.03",

        children: [
          {
            title:
              "Esc. carga transp. mat 1ª cat DMT 200 a 400m c/e (medido no corte)",
            key: "2-01-10-01-03-01",
            code: "2.01.10.01.03.01"
          }
        ]
      },
      {
        title: "COMPACTACAO DE ATERROS",
        key: "2-01-10-01-04",
        code: "2.01.10.01.04",

        children: [
          {
            title: "Compactação de aterros a 100% proctor normal",
            key: "2-01-10-01-04-01",
            code: "2.01.10.01.04.01"
          }
        ]
      }
    ]
  },
  {
    title: "DRENAGEM DE AGUAS PLUVIAIS",
    key: "2-01-10-02",
    code: "2.01.10.02",

    children: [
      {
        title: "EXECUCAO DE TUBULACÕES DE CONCRETO",
        key: "2-01-10-02-01",
        code: "2.01.10.02.01",

        children: [
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 400mm Classe PA-1, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-10-02-01-01",
            code: "2.01.10.02.01.01"
          },
          {
            title:
              "Forn e assent de Bueiro Tubular Simples de Concreto D= 1000mm Classe PA-2, incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN",
            key: "2-01-10-02-01-02",
            code: "2.01.10.02.01.02"
          }
        ]
      },
      {
        title: "POCO DE VISITA / CAIXAS DE LIGAÇÃO",
        key: "2-01-10-02-02",
        code: "2.01.10.02.02",

        children: [
          {
            title:
              "Execucao de Poco de Visita em alvenaria estrutural- D tub de 1,00 até 1,50m - H= 1,30m, incluindo reaterro em camadas sucessivas (20cm) a 100%PN",
            key: "2-01-10-02-02-01",
            code: "2.01.10.02.02.01"
          }
        ]
      },
      {
        title: "BOCA DE LEÃO",
        key: "2-01-10-02-03",
        code: "2.01.10.02.03",

        children: [
          {
            title:
              "Execução de Boca de Leão dupla em alvenaria ou concreto armado contra-barranco com fornecimento de grelha em aço, resistente à trafego pesado",
            key: "2-01-10-02-03-01",
            code: "2.01.10.02.03.01"
          }
        ]
      },
      {
        title: "DESCIDAS D' ÁGUA",
        key: "2-01-10-02-04",
        code: "2.01.10.02.04",

        children: [
          {
            title: "Descidas dágua tipo escada conforme projeto  (LANÇ3)",
            key: "2-01-10-02-04-01",
            code: "2.01.10.02.04.01"
          }
        ]
      },
      {
        title: "DISSIPADORES E MUROS DE ALAS DE SAIDA DE BUEIROS",
        key: "2-01-10-02-05",
        code: "2.01.10.02.05",

        children: [
          {
            title: "Dissipador de energia 4,00 X 3,45m (DEB 03 / DNIT)",
            key: "2-01-10-02-05-01",
            code: "2.01.10.02.05.01"
          },
          {
            title: "Boca de bueiro simples tubular BST 100CM (PADRAO DNIT)",
            key: "2-01-10-02-05-02",
            code: "2.01.10.02.05.02"
          }
        ]
      }
    ]
  },
  {
    title: "REDE DE ESGOTO - COLETOR, RECALQUE E REMANEJAMENTOS E EEE",
    key: "2-01-10-04",
    code: "2.01.10.04",

    children: [
      {
        title: "EXECUCAO DE TUBULACÕES EM PVC",
        key: "2-01-10-04-01",
        code: "2.01.10.04.01",

        children: [
          {
            title:
              "TUBO PVC OCRE PTA/BLS JEI DN200MM,  incl.preparo de vala (1a cat) e reaterro em camadas suscessivas (20cm) a 100% PN - COLETOR",
            key: "2-01-10-04-01-01",
            code: "2.01.10.04.01.01"
          }
        ]
      },
      {
        title: "POCO DE VISITA",
        key: "2-01-10-04-02",
        code: "2.01.10.04.02",

        children: [
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,0 M, H até 1,50 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-10-04-02-01",
            code: "2.01.10.04.02.01"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 1,5 M, H até 2,00 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-10-04-02-02",
            code: "2.01.10.04.02.02"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 2,0M, H até 2,50 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-10-04-02-03",
            code: "2.01.10.04.02.03"
          },
          {
            title:
              "Poço De Visita Circular para Esgoto, Em Concreto Pré-Moldado, D = 2,5M, H até 3,00 M, incl.reaterro em camadas suscessivas (20cm) a 100% PN e pescoço em concreto armado",
            key: "2-01-10-04-02-04",
            code: "2.01.10.04.02.04"
          }
        ]
      }
    ]
  },
  {
    title: "PAVIMENTAÇÃO",
    key: "2-01-10-05",
    code: "2.01.10.05",

    children: [
      {
        title: "PAVIMENTO ASFALTICO PARA TRAFEGO PESADO - AVENIDA DE ACESSO",
        key: "2-01-10-05-01",
        code: "2.01.10.05.01",

        children: [
          {
            title: "RUAS PAVIMENTADAS (SUBLEITO, SUBBASE, BASE, CAPA)",
            key: "2-01-10-05-01-01",
            code: "2.01.10.05.01.01"
          },
          {
            title:
              "GUIAS E SARJETAS EXTRUSADAS C/ 60CM LARGURA  DE BASE (GUIA BAIXA H=7,5CM) FCK 21MPa, CONSUMO DE CIMENTO >350kg/m³ de concreto",
            key: "2-01-10-05-01-02",
            code: "2.01.10.05.01.02"
          },
          {
            title:
              "GUIAS E SARJETAS EXTRUSADAS C/ 60CM LARGURA  DE BASE ((GUIA ALTA H=15CM) FCK 21MPa, CONSUMO DE CIMENTO >350kg/m³ de concreto",
            key: "2-01-10-05-01-03",
            code: "2.01.10.05.01.03"
          }
        ]
      }
    ]
  },
  {
    title: "REDE ELETRICA AÉREA E ILUMINAÇÃO PÚBLICA",
    key: "2-01-10-06",
    code: "2.01.10.06",

    children: [
      {
        title: "REDE ELETRICA AÉREA E ILUMINAÇÃO PÚBLICA",
        key: "2-01-10-06-01",
        code: "2.01.10.06.01",

        children: [
          {
            title: "Posteamento",
            key: "2-01-10-06-01-01",
            code: "2.01.10.06.01.01"
          }
        ]
      }
    ]
  },
  {
    title: "OBRAS CIVIS - EXTERNAS EMPREENDIMENTO",
    key: "2-01-10-09",
    code: "2.01.10.09",

    children: [
      {
        title: "OBRAS CIVIS - SISTEMA VIÁRIO",
        key: "2-01-10-09-01",
        code: "2.01.10.09.01",

        children: [
          {
            title:
              "Passerio em concreto armado (tela nervura Q138), com espessura de 5,0cm, fck= 30MPa c/ fibra, de cor natural e acabamento de superfície vassourado, com leito em lona plástica e lastro de brita e juntas de dilatação a cada 2 m",
            key: "2-01-10-09-01-01",
            code: "2.01.10.09.01.01"
          },
          {
            title:
              "Rampas de acessibilidade em concreto armado (tela nervurada  Q138), moldada in loco, incluindo piso podotátil, com dimensões conforme projeto",
            key: "2-01-10-09-01-02",
            code: "2.01.10.09.01.02"
          }
        ]
      }
    ]
  },
  {
    title: "REDE DE GÁS",
    key: "2-01-10-10",
    code: "2.01.10.10",
    children: [
      {
        title: "EXECUCAO DE TUBULACÕES DE GÁS",
        key: "2-01-10-10-01",
        code: "2.01.10.10.01",
        children: [
          {
            title: "Execução de Tubo PE 63MM 350MBAR",
            key: "2-01-10-10-01-01",
            code: "2.01.10.10.01.01"
          }
        ]
      }
    ]
  }
];
