import {
  ALL_VECTORS,
  FLIGHTS_LAYERS,
  VECTORS_SUBSCRIPTIONS,
} from "./GraphQL-Client/Queries";
import { useEffect, useRef, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";

import { LoadingOutlined } from "@ant-design/icons";
import MapContainer from "./MapContainer/MapContainer";
import Rainbow from "rainbowvis.js";
import { Spin } from "antd";
import { v4 as uuidv4 } from "uuid";

const colormap = new Rainbow();
colormap.setSpectrum("#ff0000", "#ffff00", "#00ff00");
const Home = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [vectors, setVectors] = useState([]);
  const now = useRef(new Date());
  const deviceId = useRef(
    window.localStorage.getItem("maply/deviceId") || uuidv4()
  );
  const { data: queryData, loading } = useQuery(ALL_VECTORS);
  const { data: queryResult, loading: loadingLayers } = useQuery(
    FLIGHTS_LAYERS,
    { variables: { account_id: "159db7e7-5a08-4d8e-bc06-a2f1a2928335" } }
  );
  const { data } = useSubscription(VECTORS_SUBSCRIPTIONS, {
    variables: { deviceId: deviceId.current, timestamp: now.current },
    shouldResubscribe: true,
  });

  useEffect(() => {
    if (queryData && queryData._vectors && queryData._vectors.length > 0) {
      setVectors([...queryData._vectors]);
      if (loading === false) {
        console.log(loadingLayers);
        if (loadingLayers === false) {
          setDataLoaded(true);
        }
      }
    } else {
      setVectors([]);
    }
  }, [queryData, loading]);

  useEffect(() => {
    console.log(data);
    // if (features.length > 0) {
    //   const newFeatures = [];
    //   features.forEach((item, i) => {
    //     // item.item_code = item.unique_string.split("-")[1];
    //     // item.sequential_id = i.toString();
    //     item.sequential_id = item.unique_string.split("-")[1];
    //     newFeatures.push(item);
    //   });
    //   setVectors(newFeatures);
    // } else {
    //   setVectors([]);
    // }
  }, [data]);

  const iconLoading = (
    <LoadingOutlined style={{ fontSize: 30, color: "white" }} spin />
  );

  if (!dataLoaded) {
    return (
      <div className="splash-loading-screen">
        <Spin tip="Carregando..." indicator={iconLoading} />
      </div>
    );
  }

  return (
    <div>
      {dataLoaded && (
        <div>
          <MapContainer
            vectors={vectors}
            deviceId={deviceId.current}
            layers={queryResult.flight_layers || []}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
