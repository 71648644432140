import "antd/dist/antd.css";

import React, { useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { AuthProvider } from "./Auth";
import { ConfigProvider } from "antd";
import Login from "./Login";
import MainPage from "./MainPage";
import PrivateRoute from "./PrivateRoute";
import { client } from "./GraphQL-Client/Client";

ConfigProvider.config({
  theme: {
    primaryColor: "#007AFF",
    errorColor: "#ff4d4f",
    warningColor: "#faad14",
    successColor: "#52c41a",
    infoColor: "#1890ff",
  },
});

const App = () => {
  const [loggedUser, setLoggedUser] = useState(null);
  const PrivatePage = () => {
    return (
      <div>
        <MainPage loggedUser={loggedUser} />
      </div>
    );
  };
  return (
    <div>
      <ConfigProvider direction="ltr">
        <AuthProvider setLoggedUser={setLoggedUser}>
          <ApolloProvider client={client}>
            <Router>
              <div>
                <PrivateRoute path="/" component={PrivatePage} />
                <Route path="/login" component={Login} />
              </div>
            </Router>
          </ApolloProvider>
        </AuthProvider>
      </ConfigProvider>
    </div>
  );
};

export default App;
