import { Button, Col, Divider, Drawer, Row, Slider, Tree } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Select from "react-select";
import { treeData } from "./eapTreeData";


// const { SHOW_PARENT } = TreeSelect;

const SideBar = ({
  sidebarIsOpen,
  setSidebarIsOpen,
  jsondata,
  selectedLayers,
  setSelectedLayers,
  backgroundLayers,
  selectedBackground,
  setSelectedBackground,
  setBackgroundOpacity,
  backgroundOpacity
}) => {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  // const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const onClose = () => {
    setSidebarIsOpen(false);
  };

  const onSelect = (selectedKeysValue, info) => {
    console.log('onSelect', info);
    setSelectedKeys(selectedKeysValue);
  };

  const onCheck = (checkedKeys, info) => {
    console.log(checkedKeys);
    if (checkedKeys.length === 0) {
      setCheckedKeys([]);
      setSelectedLayers([]);
    } else {
      setCheckedKeys(checkedKeys);
      const checkedCodes = info.checkedNodes.map((item) => item.code);
      setSelectedLayers(checkedCodes);
    }
  };

  const clearAll = () => {
    setSelectedLayers([]);
    setCheckedKeys([]);
  };

  const onExpand = (expandedKeysValue) => {
    console.log('onExpand', expandedKeysValue); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const formatOptionLabel = (props) => (
    <div style={{ marginTop: "5px", marginBottom: "5px" }}>
      <div style={{ fontSize: "14px" }}>{props.label}</div>
      <div style={{ fontSize: "12x" }}>
        {moment(props.data.flight.flown_at).format("DD-MM-YYYY")}
      </div>
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "0px",
      fontSize: "12px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  const handleSelectChange = (e) => {
    console.log(e.data);
    setSelectedBackground(e.data);
    setSelectedOption(e);
  };

  return (
    <div>
      <Drawer
        title="Artesano Galleria"
        placement="left"
        onClose={onClose}
        mask={false}
        visible={sidebarIsOpen}
        width={600}
      >
        <div
          style={{ fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}
        >
          Mapa de Fundo
        </div>
        <Select
          options={backgroundLayers.filter(item => item.flight_id !== "b6c609b1-ca76-4641-bc4f-1d4f9936750b").map((item) => {
            return {
              value: item.flight_id,
              label: item.flight.name,
              key: item.flight_id,
              data: item,
            }
          })}
          styles={customStyles}
          value={selectedOption}
          formatOptionLabel={formatOptionLabel}
          onChange={(e) => handleSelectChange(e)}
          isSearchable={false}
        ></Select>
        <div
          style={{ fontSize: "14px", fontWeight: 400, marginBottom: "10px", marginTop: '15px' }}
        >
          Opacidade:
        </div>
        <Slider defaultValue={30} value={backgroundOpacity} onChange={setBackgroundOpacity} />
        <Divider />
        <div
          style={{ fontSize: "16px", fontWeight: 700, marginBottom: "10px" }}
        >
          Layers de Projeto
        </div>
        <Row>
          <Col span={18}>
            <div style={{ padding: "10px", marginBottom: "10px" }}>
              {selectedLayers.length} layers selecionados
            </div>
          </Col>
          <Col span={6}>
            <Button onClick={() => clearAll()}> Limpar tudo </Button>
          </Col>
        </Row>
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={treeData}
        />
      </Drawer>
    </div>
  );
};

export default SideBar;
