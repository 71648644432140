import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

const X_HASURA_ADMIN_SECRET = process.env.REACT_APP_X_HASURA_ADMIN_SECRET;

const defaults = {
  localState: false,
};
const resolvers = {};

const cache = new InMemoryCache();

const wsLink = new WebSocketLink({
  uri: "wss://hasura-bi-tehzk35taa-uc.a.run.app/v1/graphql",
  headers: {
    "x-hasura-admin-secret": X_HASURA_ADMIN_SECRET,
  },
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        "x-hasura-admin-secret": X_HASURA_ADMIN_SECRET,
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: "https://hasura-bi-tehzk35taa-uc.a.run.app/v1/graphql",
  headers: {
    "x-hasura-admin-secret": X_HASURA_ADMIN_SECRET,
  },
});

const httpProdLink = new HttpLink({
  uri: "https://maply-prod.hasura.app/v1/graphql",
  headers: {
    "x-hasura-admin-secret": "ee82ebe2-16d9-4055-8d2f-41249c5f60c7",
  },
});

const httpSplitLink = split(
  ({ query }) => {
    const { kind, operation, name } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "query" && name.value === 'FlightsAndOrthoLayer';
  },
  httpProdLink,
  httpLink
);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpSplitLink
);

export const client = new ApolloClient({
  link: link,
  headers: {
    "x-hasura-admin-secret": X_HASURA_ADMIN_SECRET,
  },
  cache: cache,
  defaults,
  resolvers,
});

client.onResetStore(() => cache.writeData({ data: defaults }));

export default client;
