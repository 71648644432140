import MapboxDraw from "@mapbox/mapbox-gl-draw";
import booleanContains from "@turf/boolean-contains";
import {
  feature as createFeature,
  featureCollection as createFeatureCollection
} from "@turf/helpers";
import truncate from "@turf/truncate";
import _ from "lodash";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import Rainbow from "rainbowvis.js";
import React, { useCallback, useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import { InfoSideBar } from "../InfoSidebar";
import SideBar from "../SideBar";
import MapboxGLButtonControl from "./mapboxgl-button-control";
import { getRasterSourceSpecs, MAPBOX_ACCESS_TOKEN, mouseEventPoint } from "./MapContainerUtils";

mapboxgl.workerClass = MapboxWorker;

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const colormap = new Rainbow();
colormap.setSpectrum("#ff0000", "#ffff00", "#00ff00");

const getFeatureCollection = (data) => {
  const features = createFeatureCollection(
    data.map((feature) => {
      let newFeature = { ...feature };
      if (feature.properties) {
        newFeature = { ...feature, ...feature.properties };
      }
      return createFeature(
        newFeature.geometry,
        {
          executed: newFeature.executed,
          progress: newFeature.progress,
          color: `#${colormap.colourAt(newFeature.progress * 100 || 0)}`,
          unique_string: newFeature.unique_string,
          parentCode: newFeature.item_code,
          layer: newFeature.layer,
          child: newFeature.child,
          parent: newFeature.parent,
          description: newFeature.description,
          measurement_type: newFeature.measurement_type,
          quantity: newFeature.quantity,
          unit: newFeature.unit,
          length: newFeature.length,
          internal_id: newFeature.internal_id,
        },
        { id: newFeature.id }
      )
    })
  );
  // console.log(features);
  return truncate(features, { precision: 7, coordinates: 2 });
};

const MapContainer = ({ vectors, deviceId, layers }) => {
  const mapContainer = useRef();
  const mapRef = useRef();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [backgroundOpacity, setBackgroundOpacity] = useState(100);
  // const [sourceLoaded, setSourceLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const selectedFeaturesRef = useRef([]);
  // const selectedSubParkRef = useRef(0);
  const [, setLng] = useState(-70.9);
  const [, setLat] = useState(42.35);
  const [, setZoom] = useState(9);
  // const [currentLayers, setcurrentLayers] = useState([]);
  const [dataSidebarIsOpen, setDataSidebarIsOpen] = useState(false);
  // const [selectedTypes, setSelectedTypes] = useState([]);
  // const liveSelectedTypes = useRef([]);
  // const zoomBoxRef = useRef([]);
  const drawModeRef = useRef(null);
  const startMouseCoordinates = useRef(null);
  // const endMouseCoordinates = useRef(null);
  const boxSelectElement = useRef(null);
  const selectingBox = useRef(false);
  // const selectedTypesIds = useMemo(
  //   () => selectedTypes.map((item) => item.id),
  //   [selectedTypes]
  // );


  const mapboxLayersRef = useRef([]);

  // const toggleSideBar = (event) => {
  //   setSidebarIsOpen((old) => !old);
  // };

  // console.log(layers);

  const mouseClick = (event) => {
    const metaKey = event.originalEvent.metaKey || event.originalEvent.ctrlKey;

    if (drawModeRef.current !== "draw_polygon") {
      var bbox = [
        [event.point.x - 5, event.point.y - 5],
        [event.point.x + 5, event.point.y + 5],
      ];
      var features = mapRef.current.queryRenderedFeatures(bbox, {
        layers: mapboxLayersRef.current,
      });

      selectedFeaturesRef.current.forEach((feature) => {
        mapRef.current.setFeatureState(
          { source: "features", id: feature.id },
          { selected: false }
        );
      });

      if (features.length > 0) {
        // selected item
        let items = [features[0]];

        if (metaKey) {
          const ind = selectedFeaturesRef.current.findIndex(
            (i) => i.id === features[0].id
          );
          if (ind >= 0) {
            const temp = [...selectedFeaturesRef.current];
            temp.splice(ind, 1);
            items = [...temp];
          } else {
            items = [...items, ...selectedFeaturesRef.current];
          }
        }

        selectedFeaturesRef.current = [...items];
        setSelectedFeatures([...items]);
      } else {
        selectedFeaturesRef.current = [];
        setSelectedFeatures([]);
      }

      selectedFeaturesRef.current.forEach((feature) => {
        mapRef.current.setFeatureState(
          { source: "features", id: feature.id },
          { selected: true }
        );
      });
      // setDataSidebarIsOpen(selectedFeaturesRef.current.length > 0);
    }
  };
  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [-47.002727, -22.868838],
      zoom: 14.5,
    });

    const ctrlPoint = new MapboxGLButtonControl({
      className: "chevron-right",
      title: "Open Drawer",
      eventHandler: (event) => {
        setSidebarIsOpen(true);
      },
    });

    const locPoint = new MapboxGLButtonControl({
      className: "my-location",
      title: "Reset Location",
      eventHandler: (event) => {
        mapRef.current.setCenter([-47.002727, -22.868838]);
        mapRef.current.setZoom(14.5);
      },
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    mapRef.current.addControl(ctrlPoint, "top-left");
    mapRef.current.addControl(locPoint, "top-right");

    var drawControl = new MapboxDraw({
      boxSelect: false,
      controls: {
        polygon: true,
        point: false,
        line_string: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
      },
    });
    mapRef.current.addControl(drawControl, "top-right");
    drawModeRef.current = drawControl.getMode();

    mapRef.current.on("move", () => {
      setLng(mapRef.current.getCenter().lng.toFixed(6));
      setLat(mapRef.current.getCenter().lat.toFixed(6));
      setZoom(mapRef.current.getZoom().toFixed(2));
    });
    mapRef.current.on("draw.modechange", function (e) {
      setTimeout(() => {
        drawModeRef.current = e.mode;
      }, 500);
    });
    mapRef.current.on("draw.create", function (e) {
      const poly = e.features[0];
      const mousePoints = poly.geometry.coordinates[0].map((c) =>
        mapRef.current.project(c)
      );
      const allX = mousePoints.map((mousePoint) => mousePoint.x);
      const allY = mousePoints.map((mousePoint) => mousePoint.y);
      const x = Math.min(...allX);
      const y = Math.min(...allY);
      const maxX = Math.max(...allX);
      const maxY = Math.max(...allY);

      var bbox = [
        [x, y],
        [maxX, maxY],
      ];

      var features = mapRef.current.queryRenderedFeatures(bbox, {
        layers: mapboxLayersRef.current,
      });

      const filtered = features.filter((feature) =>
        booleanContains(poly.geometry, feature.geometry)
      );

      selectedFeaturesRef.current.forEach((feature) => {
        mapRef.current.setFeatureState(
          { source: "features", id: feature.id },
          { selected: false }
        );
      });

      selectedFeaturesRef.current = filtered;
      setSelectedFeatures([...filtered]);

      selectedFeaturesRef.current.forEach((feature) => {
        mapRef.current.setFeatureState(
          { source: "features", id: feature.id },
          { selected: true }
        );
      });
      // setDataSidebarIsOpen(selectedFeaturesRef.current.length > 0);
      drawControl.deleteAll();
    });

    mapRef.current.on("load", () => {
      setMapLoaded(true);
    });

    return () => mapRef.current.remove();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mapLoaded) {
      const source = mapRef.current.getSource("features");
      if (!source) {
        mapRef.current.addSource("features", {
          type: "geojson",
          data: getFeatureCollection(vectors || []),
        });
      }
    } else {
    }
    // setSourceLoaded(true);
  }, [mapLoaded, vectors]);

  const updateData = (newFeatures) => {
    const source = mapRef.current.getSource('features');
    if (source) {
      const features = [...source._data.features];
      // newFeatures.forEach((newFeature) => {
      //   let featureToChange = features.find((item) => item.id === newFeature.id);
      //   console.log({newFeature, featureToChange});
      //   featureToChange = { ...featureToChange, id: featureToChange.id, properties: { ...newFeature.properties, color: `#${colormap.colourAt((newFeature.properties.progress * 100) || 0)}` } }
      // });
      const modFeatures = features.reduce((prev, curr) => {
        const found = newFeatures.find((item) => item.id === curr.id)
        if (found) {
          const item = { ...curr, properties: { ...found.properties, color: `#${colormap.colourAt((found.properties.progress * 100) || 0)}` } }
          return [...prev, item]
        } else {
          return [...prev, curr]
        }
      }, []);
      const newData = createFeatureCollection(modFeatures || []);
      source.setData(newData);
    }
  }

  useEffect(() => {
    if (mapboxLayersRef.current.length > 0) {
      // If I have a mapbox layer and I don't have it anymore on selectedLayers, I will need to remove from mapbox API
      // so I need to get mapboxLayers witch I don't have it in selectedLayers
      const layersToRemove = _.difference(mapboxLayersRef.current, selectedLayers);
      layersToRemove.forEach((layerId) => {
        if (mapRef.current.getLayer(layerId)) {
          mapRef.current.removeLayer(layerId);
          mapboxLayersRef.current = _.without(mapboxLayersRef.current, layerId);
        }
      });

      // const layersToModify = _.intersection(mapboxLayersRef.current, selectedLayers);
      // don't no what to do it yet.
    }

    const layersToAdd = _.difference(selectedLayers, mapboxLayersRef.current);

    layersToAdd.forEach((layerId) => {
      mapRef.current.addLayer({
        id: layerId,
        type: "line",
        source: "features",
        filter: ['in', 'parentCode', layerId],
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            "#ffffff",
            ["get", "color"],
          ],
          "line-width": 1,
          "line-opacity": 1,
        },
      }, 'gl-draw-polygon-fill-inactive.cold');
      mapboxLayersRef.current.push(layerId);
    });
  }, [selectedLayers]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log('OnMount');
    if (mapLoaded) {
      const source = mapRef.current.getSource("features");
      if (source) {
        console.log('OnMount With Sources', vectors.length);
        vectors.forEach((vector) => {
          var code = vector.code;
          console.log(vector);
          mapRef.current.addSource("features", {
            type: "geojson",
            data: getFeatureCollection(vector.vectors || []),
          });
          mapRef.current.addLayer({
            id: code,
            type: "line",
            source: "features",
            paint: {
              "line-color": [
                "case",
                ["boolean", ["feature-state", "selected"], false],
                "#ffffff",
                ["get", "color"],
              ],
              "line-width": 4,
              "line-opacity": 1,
            },
          });
        });
      }
    }
  }, []);

  // useEffect(() => {
  //   liveSelectedTypes.current = [...selectedTypesIds];
  // }, [selectedTypesIds]);

  const startBoxSelect = (event) => {
    const shiftKey = event.originalEvent.shiftKey;
    if (shiftKey) {
      mapRef.current.boxZoom.disable();
      mapRef.current.dragPan.disable();
      startMouseCoordinates.current = mouseEventPoint(
        event.originalEvent,
        mapRef.current.getContainer()
      );
      selectingBox.current = true;
    }
  };

  const cleanBoxSelect = () => {
    if (boxSelectElement.current) {
      if (boxSelectElement.current.parentNode)
        boxSelectElement.current.parentNode.removeChild(
          boxSelectElement.current
        );
      boxSelectElement.current = null;
    }

    mapRef.current.boxZoom.enable();
    mapRef.current.dragPan.enable();
    selectingBox.current = false;
  };

  const whileBoxSelect = useCallback((event) => {
    const shiftKey = event.originalEvent.shiftKey;
    if (shiftKey && selectingBox.current) {
      //TODO: change mouse cursor
      if (!boxSelectElement.current) {
        boxSelectElement.current = document.createElement("div");
        boxSelectElement.current.classList.add("mapbox-gl-draw_boxselect");
        mapRef.current.getContainer().appendChild(boxSelectElement.current);
      }

      const current = mouseEventPoint(
        event.originalEvent,
        mapRef.current.getContainer()
      );
      const minX = Math.min(startMouseCoordinates.current[0], current[0]);
      const maxX = Math.max(startMouseCoordinates.current[0], current[0]);
      const minY = Math.min(startMouseCoordinates.current[1], current[1]);
      const maxY = Math.max(startMouseCoordinates.current[1], current[1]);
      const translateValue = `translate(${minX}px, ${minY}px)`;
      boxSelectElement.current.style.transform = translateValue;
      boxSelectElement.current.style.WebkitTransform = translateValue;
      boxSelectElement.current.style.width = `${maxX - minX}px`;
      boxSelectElement.current.style.height = `${maxY - minY}px`;
    } else if (selectingBox.current) {
      cleanBoxSelect();
    }
  }, []);

  const endBoxSelect = useCallback((event) => {
    const shiftKey = event.originalEvent.shiftKey;
    if (shiftKey && selectingBox.current) {
      const bbox = [
        startMouseCoordinates.current,
        mouseEventPoint(event.originalEvent, mapRef.current.getContainer()),
      ];

      var features = mapRef.current.queryRenderedFeatures(bbox, {
        layers: mapboxLayersRef.current,
      });
      selectedFeaturesRef.current.forEach((feature) => {
        mapRef.current.setFeatureState(
          { source: "features", id: feature.id },
          { selected: false }
        );
      });

      selectedFeaturesRef.current = features;
      setSelectedFeatures([...features]);

      selectedFeaturesRef.current.forEach((feature) => {
        mapRef.current.setFeatureState(
          { source: "features", id: feature.id },
          { selected: true }
        );
      });
      // setDataSidebarIsOpen(selectedFeaturesRef.current.length > 0);
    }
    cleanBoxSelect();
  }, []);

  // END: BOX SELECTION

  useEffect(() => {
    if (mapLoaded) {
      mapRef.current.on("click", mouseClick);
      mapRef.current.on("mousedown", startBoxSelect);
      mapRef.current.on("mousemove", whileBoxSelect);
      mapRef.current.on("mouseup", endBoxSelect);
    }
    return () => {
      mapRef.current.off("click", mouseClick);
      mapRef.current.off("mousedown", startBoxSelect);
      mapRef.current.off("mousemove", whileBoxSelect);
      mapRef.current.off("mouseup", endBoxSelect);
    };
  }, [mapLoaded, endBoxSelect, whileBoxSelect]);

  useEffect(() => setDataSidebarIsOpen(selectedFeatures.length > 0), [selectedFeatures]);

  useEffect(() => {
    !!mapRef.current.getLayer('maply-background') && mapRef.current.removeLayer('maply-background');
    !!mapRef.current.getSource('maply-background') && mapRef.current.removeSource('maply-background');
    const prevLayer = (mapboxLayersRef.current.length > 0 && `${mapboxLayersRef.current[0]}`) || 'gl-draw-polygon-fill-inactive.cold';
    // console.log(selectedBackground);
    if (selectedBackground) {
      mapRef.current.addLayer({
        id: 'maply-background',
        source: getRasterSourceSpecs(selectedBackground),
        type: 'raster'
      }, prevLayer);
    }
  }, [selectedBackground]);

  useEffect(() => {
    if (mapRef.current && !!mapRef.current.getLayer('maply-background')) {
      mapRef.current.setPaintProperty('maply-background', 'raster-opacity', (backgroundOpacity  / 100))
    }
  }, [backgroundOpacity])

  return (
    <div>
      <div className="map-container" ref={mapContainer} />
      <SideBar
        sidebarIsOpen={sidebarIsOpen}
        setSidebarIsOpen={setSidebarIsOpen}
        selectedLayers={selectedLayers}
        setSelectedLayers={setSelectedLayers}
        backgroundLayers={layers}
        setSelectedBackground={setSelectedBackground}
        selectedBackground={selectedBackground}
        setBackgroundOpacity={setBackgroundOpacity}
        backgroundOpacity={backgroundOpacity}
      />
      <InfoSideBar
        visible={dataSidebarIsOpen}
        // setVisible={setDataSidebarIsOpen}
        selectedFeatures={selectedFeatures}
        deviceId={deviceId}
        onSelectedFeaturesChanged={(features) => {
          setSelectedFeatures([...features]);
          selectedFeaturesRef.current = features;
          updateData(features);
        }}
      />
    </div>
  );
};

export default MapContainer;
