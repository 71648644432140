import { gql } from "@apollo/client";

// export const VECTORS_SUBSCRIPTION = gql`
//   subscription VECTORS_SUBSCRIPTION($code: String, $deviceId: uuid!, $timestamp: timestamptz!) {
//     eap_artesano(
//       where: {
//         _vectors: { id: { _is_null: false } }
//         _and: { code: { _eq: $code } }
//       }
//     ) {
//       child
//       code
//       description
//       measurement_type
//       parent
//       quantity
//       unit
//       _vectors(where: { device_id: { _neq: $deviceId }, timestamp: { _gt: $timestamp } }) {
//         geometry
//         id
//         item_code
//         label
//         layer
//         progress
//         executed
//         lenght
//         measurement_type
//         project_id
//         unique_string
//         updated_at
//         created_at
//         device_id
//         timestamp
//       }
//     }
//   }
// `;

export const ALL_VECTORS = gql`
  query AllVectors {
    _vectors(order_by: {seq_id: asc}) {
      created_at
      executed
      geometry
      item_code
      label
      layer
      lenght
      measurement_type
      progress
      project_id
      unique_string
      updated_at
      device_id
      timestamp
      id: seq_id
    }
  }
`;

export const VECTORS_SUBSCRIPTIONS = gql`
  subscription VectorChanges($deviceId: uuid!, $timestamp: timestamptz!) {
    _vectors(where: { device_id: { _neq: $deviceId }, timestamp: { _gt: $timestamp } }) {
      created_at
      executed
      geometry
      item_code
      label
      layer
      lenght
      measurement_type
      progress
      project_id
      unique_string
      updated_at
      device_id
      timestamp
      id: seq_id
    }
  }
`;

export const UPDATE_VECTORS = gql`
  mutation UpdateVectors($ids: [bigint!]! $progress: float8!, $timestamp: timestamptz!, $deviceId: uuid!) {
    update__vectors(where: {seq_id: {_in: $ids}}, _set: {progress: $progress, timestamp: $timestamp, device_id: $deviceId}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const FLIGHTS_LAYERS = gql`
  query FlightsAndOrthoLayer($account_id: uuid!) {
    flight_layers(where: {type: {_eq: "orthomosaic"}, flight: {account_id: {_eq: $account_id}, deleted_at: {_is_null: true}}}, order_by: {flight: {flown_at: desc}}) {
      data
      id
      flight_id
      type
      created_at
      flight {
        name
        flown_at
      }
    }
  }
`;
