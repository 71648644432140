import React, { useEffect, useState } from "react";

import { Spin } from "antd";
import app from "./base.js";

export const AuthContext = React.createContext();

const LoadingApp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <Spin />
    </div>
  );
};

export const AuthProvider = ({ children, setLoggedUser }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoggedUser(user);
      setTimeout(() => {
        setPending(false);
      }, 2000);
    });
  }, []);

  if (pending) {
    return (
      <>
        <LoadingApp />
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
