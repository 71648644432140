export const mapContainerStyle = { width: "100%", height: "100vh" };

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZ2Vvcmdpb3MtdWJlciIsImEiOiJjanZidTZzczAwajMxNGVwOGZrd2E5NG90In0.gdsRu_UeU_uPi9IulBruXA";

const TILES_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzM1NTQ1MzMsImlhdCI6MTY0MjAxODUzMywic3ViIjoiZjc0YjNmYzktNTY2ZC00OTU4LThhNjItODhkN2UwODg4ZWZiIiwiaWQiOiJmNzRiM2ZjOS01NjZkLTQ5NTgtOGE2Mi04OGQ3ZTA4ODhlZmIiLCJuYW1lIjoiQXJ0ZXNhbm8gR2FsbGVyaWEiLCJlbWFpbCI6ImRyb25lQGFydGVzYW5vdXJiYW5pc21vLmNvbS5iciIsImxhbmd1YWdlIjoicHQtQlIiLCJpbmR1c3RyeSI6ImNvbnN0cnVjdGlvbiIsInN5c3RlbV91bml0IjoibWV0cmljIiwiY3VycmVudF9wbGFuX25hbWUiOiJFbnRlcnByaXNlIiwiY3VycmVudF9wbGFuX2lkIjoiZGUwY2YzMzgtNWI1Ni00MzBkLTk0NTQtOGZkODM0NmQ1MGEzIiwicm9sZXMiOnsicHJvY2Vzc2luZ19nc2QiOjEsIm51bWJlcl9vZl9pbnNwZWN0aW9uX2ltYWdlcyI6MTUwLCJtYW5hZ2Vfb3duX3Byb2plY3RzIjp0cnVlLCJhY2Nlc3NfdGhpcmRfcGFydHlfcHJvamVjdHMiOnRydWUsImFjY2Vzc19mbGlnaHRzIjp0cnVlLCJjcmVhdGVfZmxpZ2h0cyI6dHJ1ZSwiYWNjZXNzX21pc3Npb25zIjp0cnVlLCJjcmVhdGVfbWlzc2lvbnMiOnRydWUsImdyb3VuZF9jb250cm9sX3BvaW50cyI6dHJ1ZSwiY3VzdG9tX2xvZ28iOnRydWUsIm93bl9wcm9qZWN0X2Fubm90YXRpb25zIjp0cnVlLCJvd25fcHJvamVjdF9leHBvcnQiOnRydWUsIm93bl9wcm9qZWN0X2VsZXZhdGlvbl9wcm9maWxlIjp0cnVlLCJvd25fcHJvamVjdF92b2x1bWVfY2FsY3VsYXRpb24iOnRydWUsIm93bl9wcm9qZWN0X2NvbnRvdXJfbGluZXMiOnRydWUsIm51bWJlcl9vZl9hdmFpbGFibGVfc2VhdHMiOjIwfSwiYXZhdGFyX2lkIjpudWxsLCJzaWduX2luX2NvdW50IjoxNywiaW52aXRlZCI6ZmFsc2UsImNyZWF0ZWRfYXQiOiIyMDIxLTExLTE2IDA5OjM1OjE1IC0wMzAwIiwiZW1haWxfY29uZmlybWVkX2F0IjoiMjAyMS0xMS0xNiAwOTozNTo0NyAtMDMwMCIsImNvb3JkaW5hdGVfc3lzdGVtIjoidXRtIiwiYWNjb3VudF9pZCI6IjE1OWRiN2U3LTVhMDgtNGQ4ZS1iYzA2LWEyZjFhMjkyODMzNSJ9._AMypeIEHQe1_Yjp6KfchY7pUapp-5QnVHmp08V0uIg';

export const mapStyle = "mapbox://styles/mapbox/satellite-v9";

export function getLineColor(f) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    f.properties.color
  );
  const arrColor = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  return [arrColor.r, arrColor.g, arrColor.b];
}

export function renderTooltip({ hoverInfo }) {
  const { object, x, y } = hoverInfo;
  if (!object) {
    return null;
  }
  return (
    <div className="tooltip" style={{ left: x, top: y }}>
      <div>
        <div style={{ fontSize: "10px" }}>
          {object.properties.unique_string}
        </div>
        <div style={{ fontSize: "10px" }}>{object.properties.description}</div>
      </div>
    </div>
  );
}

export function mouseEventPoint(mouseEvent, container) {
  const rect = container.getBoundingClientRect();
  return [
    mouseEvent.clientX - rect.left - (container.clientLeft || 0),
    mouseEvent.clientY - rect.top - (container.clientTop || 0),
  ];
}

export function getRasterSourceSpecs(layer) {
  const minZoom = layer.data.zoom_levels[0];
  const maxZoom = layer.data.zoom_levels[layer.data.zoom_levels.length - 1];
  return {
    bounds: [layer.data.bounds.northWest.lng, layer.data.bounds.southEast.lat, layer.data.bounds.southEast.lng, layer.data.bounds.northWest.lat],
    minzoom: parseInt(minZoom.zoom_level),
    maxzoom: parseInt(maxZoom.zoom_level),
    scheme: "tms",
    type: 'raster',
    attribution: 'Maply',
    tileSize: 256,
    volatile: false,
    tiles: [
      `https://images-a.maply.io/unsafe/smart/https://storage.googleapis.com/maply-storage-production/${layer.data.url}/{z}/{x}/{y}.png?jwt_token=${TILES_TOKEN}`,
      `https://images-b.maply.io/unsafe/smart/https://storage.googleapis.com/maply-storage-production/${layer.data.url}/{z}/{x}/{y}.png?jwt_token=${TILES_TOKEN}`,
      `https://images-c.maply.io/unsafe/smart/https://storage.googleapis.com/maply-storage-production/${layer.data.url}/{z}/{x}/{y}.png?jwt_token=${TILES_TOKEN}`,
      `https://images-d.maply.io/unsafe/smart/https://storage.googleapis.com/maply-storage-production/${layer.data.url}/{z}/{x}/{y}.png?jwt_token=${TILES_TOKEN}`
    ]
  };
}
